import { styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Viewports } from '../../../../play-core/styles/Viewports';
export const TextPlaceholder = styled.div.withConfig({
  displayName: "TextPlaceholder",
  componentId: "sc-1hzgme9-0"
})(["background-color:", ";width:", "%;transition:width 100ms ease-in-out;height:15px;border-radius:2px;margin-top:", "px;margin-bottom:", "px;", "{height:10px;margin-bottom:", "px;}"], Colors.GREY_3a3a3a, ({
  width
}) => width, ({
  marginTop
}) => marginTop, ({
  marginBottom
}) => marginBottom ?? 15, Viewports.for('small'), ({
  marginBottom
}) => marginBottom ?? 8);